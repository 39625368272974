<template>
  <div>
    <b-select
      v-model="statusId"
      label="Статус"
      :error-messages="getErrorMessages(v$.statusId)"
      :items="$directory.get('auditRecommendationStatuses')"
      :loading="$loading.isLoading('auditRecommendationStatuses')"
      @blur="v$.statusId.$touch()"></b-select>
  </div>
</template>

<script lang="ts">
import { getErrorMessages } from 'best-modules/utils'
import { PropType, ref } from 'vue'
import { Dialog } from '@/dialog'
import { useVuelidate } from '@vuelidate/core'
import axios from '@/plugins/axios/index.js'
import { urlAuditRecommendationSetStatus } from '@/utils/urls'
import { fillDirectory } from '@/directory'
import { required } from '@vuelidate/validators'

export default {
  name: 'AuditRecommendationSetStatus',
  props: {
    dialog: {
      type: Object as PropType<Dialog>,
      default: 0,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const statusId = ref(null)

    const rules = { statusId: { required: required } }
    const v$ = useVuelidate(rules, { statusId })

    const submit = () => {
      return axios.post(
        urlAuditRecommendationSetStatus(
          props.dialog.dialogData.recommendationId
        ),
        { statusId: statusId.value }
      )
    }

    fillDirectory('auditRecommendationStatuses')

    return { statusId, v$, submit }
  },
}
</script>

<style scoped lang="scss"></style>
