<template>
  <div>
    <div class="d-flex justify-end mb-3">
      <v-btn @click="createAudit">
        <v-icon class="mr-3">mdi-plus</v-icon>
        <span>Створити аудит</span>
      </v-btn>
    </div>
    <b-server-table
      :key="tableRenderKey"
      :request-func="getAuditList"
      :headers="auditListHeaders"
      show-search
      show-filters
      @click:row="
        $router.push({
          name: 'audit-single',
          params: { id: $event.id },
        })
      ">
      <template #item.actions="{ item }">
        <b-action-menu
          :actions="[
            {
              title: 'Переглянути',
              to: { name: 'audit-single', params: { id: item.id } },
              icon: 'mdi-eye',
            },
            {
              title: 'Редагувати',
              action: () => updateAudit(item),
              icon: 'mdi-pencil',
              disabled: item.statusId === 3,
            },
          ]"></b-action-menu>
      </template>
      <template #filters>
        <b-server-filter-item
          v-slot="{ props }"
          directory="auditTypes"
          query-key="typeId">
          <b-select label="Тип" clearable v-bind="props"></b-select>
        </b-server-filter-item>
        <b-server-filter-item
          v-slot="{ props }"
          directory="auditStatuses"
          query-key="statusId">
          <b-select label="Статус" clearable v-bind="props"></b-select>
        </b-server-filter-item>
        <b-server-filter-item
          v-slot="{ props }"
          query-key="startScheduledCheckDate">
          <b-input-date
            label="Планова дата початку"
            modelValueFormat="iso"
            v-bind="props"></b-input-date>
        </b-server-filter-item>
        <b-server-filter-item
          v-slot="{ props }"
          query-key="endScheduledCheckDate">
          <b-input-date
            label="Планова дата закінчення"
            modelValueFormat="iso"
            v-bind="props"></b-input-date>
        </b-server-filter-item>
        <b-server-filter-item v-slot="{ props }" query-key="year">
          <b-input
            label="Рік"
            placeholder="yyyy"
            mask="20##"
            v-bind="props"></b-input>
        </b-server-filter-item>
      </template>
    </b-server-table>
  </div>
</template>

<script lang="ts">
import {
  BServerTable,
  BServerFilterItem,
  BActionMenu,
  BInputDate,
} from 'best-modules/components'
import { auditListHeaders } from './headers'
import axios from '@/plugins/axios/index.js'
import { urlAuditList } from '@/utils/urls'
import { openDialog } from '@/dialog'
import { useRouter } from 'vue-router'
import { Audit } from '@/utils/types/audit'
import { PaginationList } from 'best-modules/components/server-table/types'
import { CustomDate } from 'best-modules/utils'
import { ref } from 'vue'

export default {
  name: 'AuditList',
  components: { BServerTable, BServerFilterItem, BActionMenu, BInputDate },
  data: () => {
    return {
      auditListHeaders,
      CustomDate,
    }
  },
  setup() {
    const router = useRouter()
    const tableRenderKey = ref<number>(1)
    const getAuditList = (query): Promise<PaginationList<Audit>> => {
      return axios.get(urlAuditList(query)).then(res => res.data)
    }

    const createAudit = () => {
      openDialog({
        name: 'Audit',
        action: 'create',
        params: {
          cb: audit => {
            router.push({ name: 'audit-single', params: { id: audit.id } })
          },
        },
      })
    }
    const updateAudit = (audit: Audit) => {
      openDialog({
        name: 'Audit',
        action: 'update',
        dialogData: {
          audit,
        },
        params: {
          cb: () => (tableRenderKey.value += 1),
        },
      })
    }

    return { getAuditList, createAudit, updateAudit, tableRenderKey }
  },
}
</script>

<style scoped lang="scss"></style>
