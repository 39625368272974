<template>
  <div>
    <audit-list v-if="$route.name === 'audit-list'"></audit-list>
    <router-view v-else></router-view>
  </div>
</template>

<script lang="ts">
import AuditList from './AuditList.vue'

export default {
  name: 'AuditIndex',
  components: { AuditList },
}
</script>

<style scoped lang="scss"></style>
