<template>
  <div>
    <b-section-loader v-if="$loading.isLoading('getAudit')"></b-section-loader>
    <div v-else>
      <v-row>
        <v-col :cols="12" :md="3">
          <audit-sidebar class="mb-3"></audit-sidebar>
          <v-btn
            v-if="audit.statusId === 1"
            density="comfortable"
            block
            :loading="$loading.isLoading('takeToWork')"
            @click="takeToWork">
            Взяти в роботу
          </v-btn>
          <v-btn
            v-if="audit.statusId === 2"
            density="comfortable"
            block
            color="red"
            @click="completeAudit">
            Завершити
          </v-btn>
        </v-col>
        <v-col :cols="12" :md="9">
          <audit-general-info></audit-general-info>

          <v-divider class="mb-3 mt-2"></v-divider>

          <audit-flaw-list class="mb-3"></audit-flaw-list>

          <b-action-buttons
            v-if="audit.statusId !== 3"
            :confirm="submit"
            :reject="() => audit.$reset()"
            :disabled="!audit.$hasChanges"></b-action-buttons>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { cachedObject } from 'best-modules/utils'
import { computed, provide } from 'vue'
import { useRoute } from 'vue-router'
import { handleAsync } from 'best-modules/plugins'
import axios from '@/plugins/axios/index.js'
import {
  urlAuditSingle,
  urlAuditTakeToWork,
  urlAuditUpdate,
} from '@/utils/urls'
import { BSectionLoader, BActionButtons } from 'best-modules/components'
import { auditKey, updateAuditKey, v$Key } from './injectionKeys'
import { Audit } from '@/utils/types/audit'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useBreadcrumb } from '@/plugins/breadcrumb.js'
import { pick } from 'lodash'
import AuditSidebar from './AuditSidebar.vue'
import { openDialog } from '@/dialog'
import AuditGeneralInfo from './AuditGeneralInfo.vue'
import AuditFlawList from './AuditFlawList.vue'

export default {
  name: 'AuditSingle',
  components: {
    BSectionLoader,
    BActionButtons,
    AuditSidebar,
    AuditGeneralInfo,
    AuditFlawList,
  },
  setup() {
    const route = useRoute()
    const breadcrumb = useBreadcrumb()
    const auditId = computed(() => route.params.id)
    const audit = cachedObject<Audit>({} as Audit)
    audit.$setIgnore(
      'flaws',
      'status',
      'statusId',
      'startCheckDate',
      'endCheckDate',
      'internalControlAssessmentId',
      'internal_control_assessment'
    )

    const updateAudit = (obj: Audit): void => {
      Object.assign(audit, obj)
    }
    const updateAuditStatus = (obj: Audit): void => {
      Object.assign(
        audit,
        pick(obj, [
          'status',
          'statusId',
          'startCheckDate',
          'endCheckDate',
          'internalControlAssessmentId',
          'internal_control_assessment',
        ])
      )
    }
    const getAudit = () => {
      return handleAsync('getAudit', () => {
        return axios.get(urlAuditSingle(auditId.value)).then(res => {
          audit.$set(res.data)
          breadcrumb.set([
            {
              index: 1,
              title: audit.name,
              disabled: true,
            },
          ])
        })
      })
    }

    const rules = {
      startAuditDate: { required: required },
      endAuditDate: { required: required },
      startScheduledCheckDate: { required: required },
      endScheduledCheckDate: { required: required },
    }
    const v$ = useVuelidate(rules, audit)

    provide(auditKey, audit)
    provide(updateAuditKey, updateAudit)
    provide(v$Key, v$)

    getAudit()

    const submit = () => {
      v$.value.$validate()
      if (!v$.value.$invalid) {
        return handleAsync('updateAudit', () => {
          return axios
            .post(urlAuditUpdate(auditId.value), audit.$object)
            .then(res => {
              audit.$set(res.data)
            })
        })
      }
    }

    const takeToWork = () => {
      return handleAsync('takeToWork', () => {
        return axios.get(urlAuditTakeToWork(audit.id)).then(res => {
          updateAuditStatus(res.data)
        })
      })
    }

    const completeAudit = () => {
      openDialog({
        name: 'AuditComplete',
        dialogData: { auditId: audit.id },
        params: {
          cb: res => updateAuditStatus(res),
        },
      })
    }

    return { audit, auditId, submit, takeToWork, completeAudit }
  },
}
</script>

<style scoped lang="scss"></style>
