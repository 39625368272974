<template>
  <div>
    <b-input
      v-model="recommendation.topic"
      label="Назва завдання"
      placeholder="введыть назву"
      :error-messages="getErrorMessages(v$.topic)"
      @blur="v$.topic.$touch()"></b-input>
    <b-select
      v-model="recommendation.executorId"
      :error-messages="getErrorMessages(v$.executorId)"
      :items="$directory.get('userList')"
      :loading="$loading.isLoading('userList')"
      item-title="name"
      item-value="id"
      label="Виконавець"
      placeholder="Оберіть зі списку"
      @blur="v$.executorId.$touch()"></b-select>
    <base-date-picker
      v-model="recommendation.deadline"
      :error-messages="getErrorMessages(v$.deadline)"
      label="Термін виконання"
      time-picker
      @blur="v$.deadline.$touch()" />

    <v-textarea
      v-model="recommendation.description"
      label="Опис завдання"
      :error-messages="getErrorMessages(v$.description)"
      @blur="v$.description.$touch()"></v-textarea>
  </div>
</template>

<script lang="ts">
import { PropType, reactive } from 'vue'
import { Dialog } from '@/dialog'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import axios from '@/plugins/axios/index.js'
import { urlAuditRecommendationCreate } from '@/utils/urls'
import { getErrorMessages } from 'best-modules/utils'
import { fillDirectory } from '@/directory'
import BaseDatePicker from '@/components/inputs/BaseDatePicker.vue'

export default {
  name: 'AuditRecommendation',
  components: { BaseDatePicker },
  props: {
    dialog: {
      type: Object as PropType<Dialog>,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const recommendation = reactive({
      auditFlawId: props.dialog.dialogData.auditFlawId,
      topic: null,
      executorId: null,
      deadline: null,
      description: null,
    })

    const rules = {
      topic: { required: required },
      executorId: { required: required },
      deadline: { required: required },
      description: { required: required },
    }
    const v$ = useVuelidate(rules, recommendation)

    const submit = () => {
      return axios.post(urlAuditRecommendationCreate(), recommendation)
    }

    fillDirectory('userList')

    return {
      recommendation,
      v$,
      submit,
    }
  },
}
</script>

<style scoped lang="scss"></style>
