import { InjectionKey, Ref } from 'vue'
import { CachedObjectProxy } from 'best-modules/utils/cachedObject'
import { Audit } from '@/utils/types/audit'
import { Validation } from '@vuelidate/core'

const auditKey: InjectionKey<CachedObjectProxy<Audit>> = Symbol('audit')
const updateAuditKey: InjectionKey<(_audit: Audit) => void> =
  Symbol('updateAudit')
const v$Key: InjectionKey<Ref<Validation>> = Symbol('v$')

export { auditKey, updateAuditKey, v$Key }
