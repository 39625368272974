<template>
  <v-card variant="outlined">
    <v-card-text>
      <div class="mb-3">
        <div class="label">Назва</div>
        <div class="text-truncate max-w-max">
          <span v-tooltip="audit.name">{{ audit.name }}</span>
        </div>
      </div>

      <div class="mb-3">
        <div class="label">Тип</div>
        <div>
          {{ audit.type.name }}
        </div>
      </div>

      <div class="mb-3">
        <div class="label">Статус</div>
        <div>
          {{ audit.status.name }}
        </div>
      </div>
      <div v-if="audit.statusId === 3" class="mb-3">
        <div class="label">Оцінка внутрішнього контролю</div>
        <div>
          {{ audit.internal_control_assessment?.name || '---' }}
        </div>
      </div>

      <div class="mb-3">
        <div class="label">Рік</div>
        <div>
          {{ audit.year }}
        </div>
      </div>

      <div class="mb-3">
        <div class="label">Початок виконання</div>
        <div>
          {{ audit.startCheckDate || '---' }}
        </div>
      </div>

      <div>
        <div class="label">Завершення виконання</div>
        <div>
          {{ audit.endCheckDate || '---' }}
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { inject } from 'vue'
import { auditKey } from './injectionKeys'
export default {
  name: 'AuditSidebar',
  setup() {
    const audit = inject(auditKey)

    return { audit }
  },
}
</script>

<style scoped lang="scss"></style>
