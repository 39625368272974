<template>
  <v-row class="align-end flex-wrap-reverse mt-5">
    <v-col cols="12" md="7" sm="12">
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <b-input
            v-model="orderData.name"
            :disabled="disabled"
            hide-details
            label="Назва наказу"
            placeholder="Введіть текст"
            readonly>
          </b-input>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <b-input
            v-model="orderData.number"
            :disabled="disabled"
            hide-details
            label="Номер наказу"
            placeholder="Введіть номер">
          </b-input>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <BaseDatePicker
            v-model="orderData.date"
            :disabled="disabled"
            hide-details
            label="Дата наказу"
            time-picker />
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <b-autocomplete
            v-model="orderData.issuedUserId"
            :disabled="disabled"
            :items="issuedUsers"
            :loading="$loading.isLoading('userList')"
            S
            hide-details
            item-title="name"
            item-value="id"
            label="Підготовив"
            placeholder="Оберіть зі списку"
            @focus="$directory.fill('userList')">
          </b-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="12">
          <b-select
            v-model="orderData.typeId"
            :items="$directory.get('orderTypes', orderData.type)"
            :loading="$loading.isLoading('orderTypes')"
            hide-details
            item-title="name"
            item-value="id"
            label="Тип"
            placeholder="Оберіть зі списку"
            @focus="$directory.fill('orderTypes')">
          </b-select>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <b-select
            v-model="orderData.directionId"
            :items="$directory.get('orderDirections', orderData.direction)"
            :loading="$loading.isLoading('orderDirections')"
            hide-details
            item-title="name"
            item-value="id"
            label="Напрямок"
            placeholder="Оберіть зі списку"
            @focus="$directory.fill('orderDirections')">
          </b-select>
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <b-select
            v-model="orderData.departmentId"
            :items="$directory.get('departmentList', orderData.department)"
            :loading="$loading.isLoading('departmentList')"
            hide-details
            item-title="name"
            item-value="id"
            label="Департамент"
            placeholder="Оберіть зі списку"
            @focus="$directory.fill('departmentList')">
          </b-select>
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <BaseTextarea
            v-model="orderData.description"
            :disabled="disabled"
            :rows="3"
            hide-details
            label="Опис наказу"
            placeholder="Введіть текст">
          </BaseTextarea>
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <span class="label">Примітки</span>
          <Notes
            :disabled="disabled"
            :entityId="orderData.id"
            :entityTypeId="3"
            :notes="orderData.notes" />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="5" sm="12">
      <div>
        <Attachment label="Вкладення">
          <template #content>
            <FilesUploader
              v-model="orderData.document"
              :accept="['.pdf']"
              :disabled="disabled"
              :name-folder="orderData.number"
              class="mb-3"
              label="Файли наказу"
              main-folder="Приказы" />
            <FilesUploader
              v-model="orderData.extraDocuments"
              :disabled="disabled"
              :name-folder="orderData.number"
              label="Додаткові файли наказу"
              main-folder="Приказы" />
          </template>
        </Attachment>
        <div class="d-flex mt-3">
          <div v-if="currentFamiliarization">
            <v-btn
              v-if="isFamiliarization"
              size="small"
              style="cursor: default; pointer-events: none">
              Ознайомлений
            </v-btn>
            <v-btn
              v-else
              label="Ознайомитись"
              size="small"
              @click="familiarizationAgree"
              >Ознайомитись
            </v-btn>
          </div>
        </div>
        <div class="info">
          <div>
            <span
              >До ознайомлення:
              {{ orderData?.familiarizations?.length }} користувачів</span
            >
            <span
              >Прочитано:
              {{
                orderData.familiarizations?.filter(u => u.isFamiliarization)
                  .length || 0
              }}
              користувачів</span
            >
          </div>
          <div>
            <span>Дата створення: {{ orderData.created_at }}</span>
            <span>Дата оновлення: {{ orderData.updated_at }}</span>
            <span>Автор: {{ getUserName(orderData.author) }}</span>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { computed } from 'vue'
import { getUserName, navigateToItem } from '@/utils/helpers.js'
import { useFamiliarization } from '../familiarization.js'
import Attachment from '@/components/Attachment.vue'
import FilesUploader from '@/components/filesUploader/FilesUploader.vue'
import BaseDatePicker from '@/components/inputs/BaseDatePicker.vue'
import { getDirectory } from '@/directory/index.ts'
import Notes from '@/components/Notes.vue'

export default {
  components: {
    Notes,
    BaseDatePicker,
    FilesUploader,
    Attachment,
  },
  emits: ['update:order'],
  props: {
    order: { type: Object },
    disabled: { type: Boolean },
  },
  setup(props, { emit }) {
    const orderData = computed({
      get: () => props.order,
      set: val => emit('update:order', val),
    })

    const issuedUsers = computed(() => {
      const unspecifiedItem = {
        name: 'Не вказано',
        id: null,
      }

      return [
        unspecifiedItem,
        ...getDirectory('userList', orderData.value.issued_user),
      ]
    })

    return {
      ...useFamiliarization(orderData.value),
      orderData,
      issuedUsers,
      getUserName,
      navigateToItem,
    }
  },
}
</script>

<style lang="scss" scoped>
.info {
  background: #f5f6f8;
  padding: 8px;
  display: flex;
  justify-content: space-between;

  span {
    display: block;
    font-size: 12px;
    color: #b5b7c0;
    margin-bottom: 4px;
  }
}
</style>
