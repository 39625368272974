<template>
  <div>
    <div>
      <div>
        <note-item
          v-for="note in displayedNotes"
          :key="note.id"
          :note="note"
          :loading="loadingState.delete"
          class="note-transition mb-3"
          @delete="deleteNote"></note-item>
      </div>
      <div class="mb-3">
        <v-btn
          v-if="displayedNotes.length < localNotes.length"
          size="small"
          style="padding: 0 !important"
          variant="text"
          @click="showAllNotes"
          >Показати всі
        </v-btn>
      </div>
      <BaseTextarea
        v-model="newNote"
        class="mb-3"
        placeholder="Нова примітка"
        rows="2" />
      <v-btn
        :disabled="!newNote"
        :loading="loadingState.add"
        class="w-100"
        @click="addNote">
        Додати
      </v-btn>
    </div>
  </div>
</template>

<script>
import NoteItem from './NoteItem.vue'
import { computed, reactive, ref, watch } from 'vue'
import { axios } from '@/plugins/index.js'
import { urlNoteCreate, urlNoteDelete } from '@/utils/urls.js'
import { useStore } from 'vuex'

export default {
  components: { NoteItem },
  props: {
    dialog: {
      type: Object,
    },
  },
  setup(props) {
    const store = useStore()
    const user = computed(() => store.state.user.userData)

    const newNote = ref(null)
    const localNotes = ref([])
    localNotes.value.push(...(props.dialog.dialogData.notes || []))
    const loadingState = reactive({
      add: false,
      delete: false,
    })
    const displayedNotes = ref([...localNotes.value.slice(0, 2)])

    watch(
      () => localNotes.value.length,
      () => {
        // When localNotes change, update displayedNotes with a transition
        displayedNotes.value = [...localNotes.value.slice(0, 2)]
      }
    )

    const addNote = () => {
      loadingState.add = true

      return axios
        .post(urlNoteCreate(), {
          note: newNote.value,
          entityId: props.dialog.dialogData.entityId,
          entityTypeId: props.dialog.dialogData.entityTypeId,
        })
        .then(res => {
          localNotes.value.push(res.data)

          props.dialog.dialogData.updateNotes(localNotes.value)

          newNote.value = null
        })
        .finally(() => {
          loadingState.add = false
        })
    }

    const deleteNote = async noteId => {
      loadingState.delete = noteId

      return axios
        .delete(urlNoteDelete(noteId))
        .then(() => {
          localNotes.value = localNotes.value.filter(c => c.id !== noteId)
          props.dialog.dialogData.updateNotes(localNotes.value)
        })
        .finally(() => {
          loadingState.delete = false
        })
    }

    const showAllNotes = () => {
      displayedNotes.value = [...localNotes.value]
    }

    return {
      loadingState,
      localNotes,
      newNote,
      addNote,
      deleteNote,
      showAllNotes,
      displayedNotes,
      user,
    }
  },
}
</script>

<style lang="scss" scoped>
.note-transition {
  transition: opacity 0.5s ease, max-height 0.5s ease;
  max-height: 1000px; // Set a value larger than the expected height, adjust as needed
}

.note-transition.hidden {
  opacity: 0;
  max-height: 0;
}
</style>
