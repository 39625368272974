<template>
  <div>
    <b-input
      v-model="audit.name"
      label="Назва"
      placeholder="введіть назву"
      :error-messages="getErrorMessages(v$.name)"
      @blur="v$.name.$touch()"></b-input>
    <v-row>
      <v-col :cols="4">
        <b-input
          v-model="audit.year"
          placeholder="yyyy"
          label="Рік"
          mask="20##"
          :error-messages="getErrorMessages(v$.year)"
          @blur="v$.year.$touch()"></b-input>
      </v-col>
      <v-col :cols="8">
        <b-select
          v-model="audit.typeId"
          :items="$directory.get('auditTypes', audit.type)"
          :loading="$loading.isLoading('auditTypes')"
          label="Тип"
          :error-messages="getErrorMessages(v$.typeId)"
          @focus="$directory.fill('auditTypes')"
          @blur="v$.typeId.$touch()"></b-select>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { PropType, reactive } from 'vue'
import { Dialog } from '@/dialog'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import axios from '@/plugins/axios/index.js'
import { urlAuditCreate, urlAuditUpdate } from '@/utils/urls'
import { getErrorMessages } from 'best-modules/utils'
import { fillDirectory } from '@/directory'
import { Audit } from '@/utils/types/audit'

export default {
  name: 'Audit',
  props: {
    dialog: {
      type: Object as PropType<Dialog>,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const audit = reactive<Audit>({
      typeId: null,
      name: null,
      year: null,
    } as Audit)
    if (props.dialog.action === 'update') {
      Object.assign(audit, props.dialog.dialogData.audit)
    }
    if (props.dialog.action === 'create') {
      fillDirectory('auditTypes')
    }

    const rules = {
      typeId: { required: required },
      name: { required: required },
      year: { required: required },
    }
    const v$ = useVuelidate(rules, audit)

    const submit = () => {
      const url =
        props.dialog.action === 'create'
          ? urlAuditCreate()
          : urlAuditUpdate(audit.id)
      return axios.post(url, audit)
    }

    return { audit, v$, submit }
  },
}
</script>

<style scoped lang="scss"></style>
