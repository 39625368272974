<template>
  <div>
    <b-select
      v-model="internalControlAssessmentId"
      :items="$directory.get('auditControlAssessment')"
      :loading="$loading.isLoading('auditControlAssessment')"
      label="Оцінка внутрішнього контролю"
      :error-messages="getErrorMessages(v$.internalControlAssessmentId)"
      @focus="$directory.fill('auditControlAssessment')"
      @blur="v$.internalControlAssessmentId.$touch()"></b-select>
  </div>
</template>

<script lang="ts">
import { PropType, ref } from 'vue'
import { Dialog } from '@/dialog'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { fillDirectory } from '@/directory'
import { getErrorMessages } from 'best-modules/utils'
import axios from '@/plugins/axios/index.js'
import { urlAuditComplete } from '@/utils/urls'

export default {
  name: 'AuditComplete',
  props: {
    dialog: {
      type: Object as PropType<Dialog>,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const internalControlAssessmentId = ref()
    const rules = { internalControlAssessmentId: { required: required } }
    const v$ = useVuelidate(rules, { internalControlAssessmentId })

    const submit = () => {
      return axios.post(urlAuditComplete(props.dialog.dialogData.auditId), {
        internalControlAssessmentId: internalControlAssessmentId.value,
      })
    }

    fillDirectory('auditControlAssessment')

    return { internalControlAssessmentId, v$, submit }
  },
}
</script>

<style scoped lang="scss"></style>
