<template>
  <div>
    <b-input
      v-model="flaw.text"
      placeholder="введіть факт недоліку"
      :error-messages="getErrorMessages(v$.text)"
      @blur="v$.text.$touch()">
    </b-input>
    <b-select
      v-model="flaw.riskTypeId"
      :items="$directory.get('riskTypes', flaw.risk_type)"
      :loading="$loading.isLoading('riskTypes')"
      label="Вид ризику"
      :error-messages="getErrorMessages(v$.riskTypeId)"
      @focus="$directory.fill('riskTypes')"
      @blur="v$.riskTypeId.$touch()"></b-select>
    <b-select
      v-model="flaw.riskLevelId"
      :items="$directory.get('riskLevels', flaw.risk_level)"
      :loading="$loading.isLoading('riskLevels')"
      label="Рівень ризику"
      :error-messages="getErrorMessages(v$.riskLevelId)"
      @focus="$directory.fill('riskLevels')"
      @blur="v$.riskLevelId.$touch()"></b-select>
    <base-textarea
      v-model="flaw.factorFlaw"
      placeholder="введіть факт недоліку"
      :error-messages="getErrorMessages(v$.factorFlaw)"
      @blur="v$.factorFlaw.$touch()">
    </base-textarea>
  </div>
</template>

<script lang="ts">
import { PropType, reactive } from 'vue'
import { Dialog } from '@/dialog'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import axios from '@/plugins/axios/index.js'
import { urlAuditFlawCreate, urlAuditFlawUpdate } from '@/utils/urls'
import { getErrorMessages } from 'best-modules/utils'
import { AuditFlaw } from '@/utils/types/audit'

export default {
  name: 'AuditFlaw',
  props: {
    dialog: {
      type: Object as PropType<Dialog>,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const flaw = reactive<AuditFlaw>({
      auditId: props.dialog.dialogData.auditId,
      text: null,
      riskTypeId: null,
      riskLevelId: null,
      factorFlaw: null,
    } as AuditFlaw)
    if (props.dialog.action === 'update') {
      Object.assign(flaw, props.dialog.dialogData.flaw)
    }
    const rules = {
      text: { required: required },
      riskTypeId: { required: required },
      riskLevelId: { required: required },
      factorFlaw: { required: required },
    }
    const v$ = useVuelidate(rules, flaw)

    const submit = () => {
      if (props.dialog.action === 'create') {
        return axios.post(urlAuditFlawCreate(), flaw)
      }
      if (props.dialog.action === 'update') {
        return axios.post(urlAuditFlawUpdate(flaw.id), flaw)
      }
    }

    return { flaw, v$, submit }
  },
}
</script>

<style scoped lang="scss"></style>
