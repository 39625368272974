<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems">
      <template #prepend>
        <v-icon class="mr-2" @click="$router.push({ name: 'home' })"
          >mdi-home
        </v-icon>
      </template>
      <template #divider>
        <v-icon icon="mdi-chevron-right"></v-icon>
      </template>
      <template #item="{ item, index }">
        <div
          v-tooltip:bottom="item.title"
          style="max-width: 250px"
          class="text-truncate">
          <v-breadcrumbs-item
            v-if="item.title"
            :title="item.title"
            class="cursor-pointer d-inline"
            :disabled="breadcrumbItems.length === index + 1 || item.disabled"
            :to="
              breadcrumbItems.length === index + 1 ? null : item.to
            "></v-breadcrumbs-item>
        </div>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
import { useBreadcrumb } from '@/plugins'
import { computed } from 'vue'

export default {
  setup() {
    const { items } = useBreadcrumb()
    const home = { icon: 'pi pi-home', href: { name: 'home' } }
    const breadcrumbItems = computed(() => items.value || [])
    return { home, breadcrumbItems }
  },
}
</script>

<style lang="scss" scoped></style>
