<template>
  <v-row>
    <v-col :cols="12" :md="4">
      <b-input-date
        v-model:start-date="audit.startAuditDate"
        v-model:end-date="audit.endAuditDate"
        range
        label="Дата аудиту"
        :readonly="audit.statusId === 3"
        :error-messages="
          getErrorMessages(v$.startAuditDate).concat(
            getErrorMessages(v$.endAuditDate)
          )
        "></b-input-date>
    </v-col>
    <v-col :cols="12" :md="4">
      <b-input-date
        v-model:start-date="audit.startScheduledCheckDate"
        v-model:end-date="audit.endScheduledCheckDate"
        range
        label="Планова дата аудиту"
        :readonly="audit.statusId === 3"
        :error-messages="
          getErrorMessages(v$.startScheduledCheckDate).concat(
            getErrorMessages(v$.endScheduledCheckDate)
          )
        "></b-input-date>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { computed, inject } from 'vue'
import { updateAuditKey, auditKey, v$Key } from './injectionKeys'
import { getErrorMessages } from 'best-modules/utils'
import { BInputDate } from 'best-modules/components'

export default {
  name: 'AuditGeneralInfo',
  components: { BInputDate },
  emits: ['update:audit'],
  methods: { getErrorMessages },
  setup() {
    const updateAudit = inject(updateAuditKey)
    const audit = computed({
      get: () => inject(auditKey),
      set: v => updateAudit(v),
    })

    const v$ = inject(v$Key)

    return { audit, v$ }
  },
}
</script>

<style scoped lang="scss"></style>
