<template>
  <div class="w-100">
    <div class="d-flex justify-space-between">
      <div>
        <div class="w-100 text-grey-darken-2">
          <span v-for="(row, idx) in note.note.split('\n')" :key="idx">
            {{ row }} <br />
          </span>
        </div>
        <div
          class="mt-2 text-grey-darken-2 d-flex align-center justify-space-between gap-3">
          <span class="d-block">{{
            formatDate(note.created_at, 'response')
          }}</span>
          <slot name="showAllNotes"></slot>
        </div>
      </div>
      <v-btn
        v-if="!hideDelete"
        :disabled="!isAuthor"
        :loading="loading === note.id"
        icon
        size="x-small"
        variant="text"
        @click="$emit('delete', note.id)">
        <v-icon icon="mdi-delete" size="small" />
      </v-btn>
    </div>
  </div>
</template>

<script>
import { useStore } from '@/plugins/index.js'
import { computed, inject } from 'vue'
import { getUserName } from '@/utils/helpers.js'
import { formatDate } from '@/plugins/axios/helpers.js'

export default {
  props: {
    note: { type: Object, required: true },
    loading: { type: [Number, Boolean] },
    hideDelete: { type: Boolean },
  },
  emits: ['delete'],
  methods: { formatDate, getUserName },

  setup(props) {
    const store = useStore()
    const loadingState = inject('loadingState', {})

    const userId = computed(() => store.state.user.userData?.id)
    const isAuthor = computed(() => {
      return props.note.userId === userId.value
    })

    return { loadingState, isAuthor }
  },
}
</script>

<style lang="scss"></style>
